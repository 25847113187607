import React, { Component } from "react";
import axios from "axios";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import Checkbox from "../Checkbox";
import CustomDropdown from "../CustomDropdown";
import RadioButton from "../RadioButton";
import GetAncestorElement from "../Helper/GetAncestorElement";
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import Interest from "./Interest";
class EmailPreference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preferenceFormData: {
                pageId: this.props.PageID ? this.props.PageID : "",
                dbName: this.props.DBName ? this.props.DBName : "",
                emailAddress: this.props.EmailAddress ? this.props.EmailAddress : "",
                postcode: this.props.Postcode ? this.props.Postcode : "",
                interests: {},
                specifiedGender: "",
            },
            genderList: {},
            verifiedRecaptcha: false,
            expiredRecaptcha: false,
            load: false,
            recaptchaLoaded: false,
            responseData: {
                IsSuccessful: false,
                Message: "",
                ApiResponse: null
            },
            relatedFieldValues: {}
        };
    }

    componentDidMount() {
        const {
            Interests = null,
            GenderOptions = null,
        } = this.props || {};
        let interestsObj = {};
        let genderObj = {};
        let specifiedGender = ""
        if (Interests) {
            Interests.map((listItem, index) => {
                const {
                    Id: id = null,
                    Name: name = null,
                    Selected: selected = null,
                } = listItem.fields || {};
                let selectedValue = selected && selected.value === true ? true : false;
                let keyId = id.value;
                interestsObj[keyId] = selectedValue;
            })
        }
        if (GenderOptions) {
            GenderOptions.map((listItem, index) => {
                const {
                    Id: id = null,
                    Name: name = null,
                    Selected: selected = null,
                    RelatedFieldCurrentValue = null
                } = listItem.fields || {};
                let keyId = id.value;
                let selectedValue = selected && selected.value === true ? true : false;
                interestsObj[keyId] = selectedValue;
                genderObj[keyId] = selectedValue;
                if (RelatedFieldCurrentValue && RelatedFieldCurrentValue.value) specifiedGender = RelatedFieldCurrentValue.value
            })
        }
        this.setState({
            ...this.state,
            preferenceFormData: {
                ...this.state.preferenceFormData,
                interests: interestsObj,
                specifiedGender: specifiedGender
            },
            genderList: genderObj
        },
            this.triggerActiveElement());

        setTimeout(() => {
            this.setState({ load: true });
        }, 1500);
    }

    componentDidUpdate() {
        const successContainer = document.querySelector('.modal-button-successmessage')
        if (successContainer) document.querySelector('.email-preference').scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    recaptchaLoaded = () => {
        console.log("Successful Load Recaptcha");
        this.setState({ recaptchaLoaded: true });
    };

    recaptchaExpired = () => {
        this.setState({
            verifiedRecaptcha: false,
            expiredRecaptcha: true
        });
    };

    recaptchaVerifyResponse = response => {
        if (response) {
            this.setState({
                verifiedRecaptcha: true,
                expiredRecaptcha: false
            });
        } else {
            this.setState({ verifiedRecaptcha: false });
        }
    };

    getSubscribeModule = (Endpoint) => {
        if (Endpoint.includes('subscribe')) return 'Subscription module'
        if (Endpoint.includes('footer')) return 'Footer module'
        return 'Sign up module'
    }

    triggerActiveElement = () => {
        const emailPreferenceContainer = document.getElementsByClassName(`${this.props.Container ? this.props.Container : 'update-preferences'}`);
        if (emailPreferenceContainer.length) {
            let activeCheckboxList = emailPreferenceContainer[0].querySelectorAll(".form-checkbox .is-active");
            let activeRadioList = emailPreferenceContainer[0].querySelectorAll(".form-radiobutton .is-active");
            if (activeCheckboxList.length > 0) {
                for (let i = 0; i < activeCheckboxList.length; i++) {
                    activeCheckboxList[i].click();
                }
            }
            if (activeRadioList.length > 0) {
                for (let i = 0; i < activeRadioList.length; i++) {
                    activeRadioList[i].click();
                }
            }
        }
    }
    handleEmailPreference = async (e) => {
        const {
            IsModal = false,
            EmailPreferenceResponse = null,
            FirstName = null,
            LastName = null,
            EmailAddress = null,
            Postcode = null,
            ValidateFields = {},
            EnableRecaptcha = false,
        } = this.props || {};
        const {
            pageId,
            dbName,
            emailAddress,
            interests,
            postcode,
        } = this.state.preferenceFormData;

        const data = {
            pageId,
            dbName,
            emailAddress,
            postcode,
            interests
        }

        const submitButton = document.querySelector('.email-preference__button')
        submitButton.classList.toggle('disabled')
        let complete = true
        // Signup validation
        if (this.props.Endpoint.includes('signup')) {
            if (!EmailAddress || !Postcode) { //simulate email api error response
                [EmailAddress, Postcode].forEach((reqField, index) => {
                    if (!reqField) EmailPreferenceResponse(false, {
                        "IsSuccessful": false,
                        "Message": `${index < 1 ? 'Email' : 'Postcode'} is required`,
                        "ApiResponse": index < 1 ? 2 : 5
                    })
                })
                complete = false
            }
            // signup prop fields validation
            if (Object.values(ValidateFields).some(el => el.error === true)) complete = false
            if (EnableRecaptcha && EnableRecaptcha.value && !this.state.verifiedRecaptcha) {
                complete = false
                this.setState({ ...this.setState, expiredRecaptcha: true })
            }

            data.firstName = FirstName
            data.lastName = LastName
            data.emailAddress = EmailAddress
            data.postcode = Postcode.toString();
        }
        // Additional validations
        if (this.props.Endpoint.includes('signup') || this.props.Endpoint === 'updatepreference') {
            // check if there's still an error
            let relatedFieldInput = document.body.querySelector(".related-field-input.is-active.is-required")

            if (relatedFieldInput) {
                if (!relatedFieldInput.value) {
                    complete = false
                    relatedFieldInput.classList.add('box-error')
                    relatedFieldInput.focus()
                }
            }

            // catch other box error
            if (document.body.querySelector(".box-error")) complete = false
            data.specifiedGender = this.state.preferenceFormData.specifiedGender
        }

        // Check privacy checkbox
        const privacyCheckbox = document.body.querySelector("#privacy-checkbox");
        console.log('privacyCheckboxprivacyCheckboxprivacyCheckboxprivacyCheckboxprivacyCheckbox');
        console.log(privacyCheckbox);
        if (privacyCheckbox && !privacyCheckbox.checked) {
            console.log('not checked');
            privacyCheckbox.classList.add("is-error");
            complete = false;
        } else {
            privacyCheckbox.classList.remove("is-error");
        }

        // API CALL
        if (complete) {
            const url = `/api/sitecore/subscription/${this.props.Endpoint.includes('signup') ? 'signup' : 'updatepreference'}`;
            let response = await axios({
                method: "POST",
                url,
                data: data
            });

            if (response && response.data) {
                if (this.props.Endpoint.includes('signup')) {
                    // signup api response
                    if (EmailPreferenceResponse) {
                        if (response.data.ApiResponse === 0 || response.data.ApiResponse === 3) {
                            //Push selected interests to array if checked
                            let categoryList = []
                            let categoryCheck = Object.entries(this.state.preferenceFormData.interests)
                            let genderCheck = Object.keys(this.state.genderList)
                            for (let [interestsID, interestsChecked] of categoryCheck) {
                                if (interestsChecked === true && !genderCheck.includes(interestsID)) categoryList.push(document.getElementById(interestsID).value);
                            }
                            //Set selectedGender value from FormData active Gender
                            let selectedGender
                            if (this.state.preferenceFormData.specifiedGender === "") {
                                let genderSelect = Object.entries(this.state.genderList)
                                for (let [genderID, genderChecked] of genderSelect) {
                                    if (genderChecked === true) selectedGender = document.getElementById(genderID).value;
                                }
                            } else { selectedGender = this.state.preferenceFormData.specifiedGender } // Set selectedGender value from specifiedGender
                            //GA tracking - Sign Up module
                            // "Subscription module" for subscribe
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: "subscribeSubmit",
                                    subscribeModule: this.getSubscribeModule(this.props.Endpoint),
                                    user: {
                                        details: {
                                            email: data.emailAddress,
                                            postcode: data.postcode,
                                            first_name: data.firstName,
                                            last_name: data.lastName,
                                            categories_interested_in: categoryList.join(", "),
                                            gender: selectedGender,
                                        }
                                    }
                                },
                                dataLayerName: "dataLayer"
                            });
                            EmailPreferenceResponse(true, response.data, true)
                            this.setState({ ...this.state, responseData: { ...response.data } })
                        }
                        else if (response.data.ApiResponse === 2) {
                            submitButton.classList.toggle('disabled')
                            EmailPreferenceResponse(false, response.data, true)
                        } else {
                            submitButton.classList.toggle('disabled')
                            this.setState({ ...this.state, responseData: { ...response.data } })
                        }
                    }
                    if (!IsModal) document.getElementsByTagName("body")[0].classList.remove("modal-open");
                } else { //for update preference
                    if (response.data.IsSuccessful) {
                        if (EmailPreferenceResponse) {
                            EmailPreferenceResponse(true);
                        }
                        document.getElementsByTagName("body")[0].classList.remove("modal-open");
                    }
                }
            }
        } else {
            submitButton.classList.toggle('disabled') //enable submit button
            setTimeout(() => {
                let invalidField = document.body.querySelector(".box-error")
                if (invalidField) {
                    invalidField.scrollIntoView({ block: 'center' });
                }
            }, 250);
        }
    };

    handlePreferencesUpdate = (value) => {
        let interestsValue = this.state.preferenceFormData.interests;
        let genderList = this.state.genderList;
        let specifiedGender = ""
        for (var list in genderList) {
            // get specifiedGender from input
            if (value.genderValue.includes('+')) {
                let [id, inputValue] = value.genderValue.split('+')
                specifiedGender = inputValue
                if (id.includes('-')) id = id.split('-')[0] // remove split 1234-input id-value
                if (id === list) {
                    interestsValue[list] = true
                    genderList[list] = true
                } else {
                    interestsValue[list] = false
                    genderList[list] = false
                }
            } else {
                if (value.genderValue === list) {
                    interestsValue[list] = true
                    genderList[list] = true
                }
                else {
                    interestsValue[list] = false
                    genderList[list] = false
                }
                specifiedGender = ""
            }
        }

        this.setState({
            preferenceFormData: {
                ...this.state.preferenceFormData,
                specifiedGender: specifiedGender,
                interests: interestsValue
            },
            genderList: genderList
        });
        if (!this.props.HideDropdown) {
            const emailPreferenceWrapper = GetAncestorElement(value.element, "email-preference");
            if (emailPreferenceWrapper) {
                const buttonList = emailPreferenceWrapper.querySelectorAll(`[id="${value.element.id}"]`);
                for (let i = 0; i < buttonList.length; i++) {
                    let buttonElem = buttonList[i];
                    if (buttonElem !== value.element) {
                        buttonElem.click();
                    }
                }
            }
        }
    };

    handleCheckboxClick = (event) => {
        let interestsValue = this.state.preferenceFormData.interests;
        const checkboxId = event.target.id;
        const isChecked = event.target.checked;
        interestsValue[checkboxId] = isChecked;
        this.setState({
            preferenceFormData: {
                ...this.state.preferenceFormData,
                interests: interestsValue
            },
        });
    }

    hanlePrivacyCheck = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            event.target.classList.remove('is-error');
        } else {
            event.target.classList.add('is-error');
        }
    }

    handleScroll = (e) => {
        const dropdownList = e.target.querySelector(".dropdown-list");
        if (dropdownList) {
            dropdownList.setAttribute("hidden", true);
        }
    }

    renderModalMessage = (responseData) => {
        return (
            <div className="modal-button-successmessage">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path fill="#307a53" d="M9.23 18.317a.997.997 0 0 1-.759.346.997.997 0 0 1-.759-.346l-4.19-4.189a1 1 0 0 1 1.415-1.414l3.534 3.534L19.076 5.643a1 1 0 0 1 1.414 1.414L9.23 18.317z" stroke="#307a53" strokeWidth="2px" />
                    </g>
                </svg>
                <label>{responseData.Message}</label>
            </div>
        )

    }

    render() {
        const {
            Title = null,
            Description = null,
            ButtonName = null,
            Interests = null,
            GenderDescription = null,
            GenderOptions = null,
            RadioGroupName = null,
            HideDropdown = false,
            EnableRecaptcha = null,
            RecaptchaPublicKey: PublicKey = null,
            RecaptchaPrivateKey: PrivateKey = null,
            PrivacyPolicyText = null,
        } = this.props || {};
        const { verifiedRecaptcha, expiredRecaptcha, load, responseData } = this.state;
        return (
            <div className="email-preference">
                {Title &&
                    Title.value &&
                    <div className="email-preference__header">
                        <Text field={Title} />
                    </div>
                }
                <div className={`email-preference__options ${HideDropdown ? "hide-dropdown" : ""}`}
                    onScroll={this.handleScroll}>
                    {Description &&
                        Description.value &&
                        <div className="email-preference__description">
                            <Text field={Description} />
                        </div>
                    }
                    {
                        (Interests || GenderOptions) &&
                        <div className="email-preference__interests">
                            {Interests && <Interest interests={Interests} handleCheckboxClick={this.handleCheckboxClick} />}
                            {GenderOptions &&
                                <div className={`col-12 ${!HideDropdown ? "has-dropdown" : ""}`}>
                                    {GenderDescription &&
                                        GenderDescription.value &&
                                        <div className="email-preference__gender-description"><Text field={GenderDescription} /></div>
                                    }
                                    <div className="email-preference__gender-wrapper">
                                        <RadioButton
                                            RadioGroupName={RadioGroupName}
                                            data={GenderOptions}
                                            onChange={(genderValue, element) =>
                                                this.handlePreferencesUpdate({ genderValue, element })
                                            }
                                            relatedFieldsValues={this.state.relatedFieldValues}
                                            setRelatedFieldsValues={(vals) => this.setState({ relatedFieldValues: vals })}
                                        />
                                        {!HideDropdown &&
                                            <CustomDropdown
                                                data={GenderOptions}
                                                placeholder={"Select Gender"}
                                                onChange={(genderValue, element) =>
                                                    this.handlePreferencesUpdate({ genderValue, element })
                                                }
                                                id="email-pref-dropdown"
                                                relatedFieldsValues={this.state.relatedFieldValues}
                                                setRelatedFieldsValues={(vals) => this.setState({ relatedFieldValues: vals })}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {load &&
                    EnableRecaptcha &&
                    EnableRecaptcha.value &&
                    PublicKey &&
                    PublicKey.value && (
                        <div
                            className={
                                "form-group form-recaptcha " +
                                (verifiedRecaptcha ? "verifiedRecaptcha " : " ") +
                                (expiredRecaptcha ? "box-error" : " ")
                            }
                        >
                            <ReCAPTCHA
                                size="normal"
                                sitekey={
                                    PublicKey && PublicKey.value
                                        ? PublicKey.value
                                        : "6LeqAfYUAAAAAGK1oZ1NzfVUFeC4kqJOe16XklGg"
                                }
                                onChange={this.recaptchaVerifyResponse}
                                onExpired={this.recaptchaExpired}
                                asyncScriptOnLoad={this.recaptchaLoaded}
                            />
                            <div className="form-control-validation">
                                <label className="text-error">
                                    <label>ReCaptcha is required</label>
                                </label>
                            </div>
                        </div>
                    )}

                <div class="form-group form-checkbox">
                    <label class="email-preference__privacy-checkbox">
                        <input
                            type="checkbox" class="" id="privacy-checkbox" name="privacy-checkbox" value="agree"
                            required={true}
                            onChange={this.hanlePrivacyCheck}
                        />
                        <div>
                            <RichText field={PrivacyPolicyText} />
                            <label class="text-message text-error">Concent is required</label>
                        </div>
                    </label>
                </div>
                <div className={`email-preference__button-container ${this.props.IsModal ? 'modal-button' : ''}`}>
                    <button
                        className="email-preference__button"
                        onClick={(e) => {
                            this.handleEmailPreference(e);
                        }}
                    >
                        {ButtonName}
                    </button>
                    {
                        responseData.ApiResponse === 0 && responseData.IsSuccessful && this.props.IsModal && (
                            <div className="success-message">
                                {this.renderModalMessage(responseData)}
                            </div>
                        )
                    }
                </div>
                {responseData.ApiResponse === 4 && (
                    <div>
                        <label className="text-error">{responseData.Message}</label>
                    </div>
                )}
            </div>
        );
    }
}

export default EmailPreference;