import React, { Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import EmailPreference from "../../../Common/EmailPreference";
import BaseComponent from "../../../Base/BaseComponent";
import SubscribeForm from "../../../Common/SubscribeForm/SubscribeForm";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageId: "",
      dbName: "",
      fname: "",
      lname: "",
      email: "",
      postcode: "",
      success: false,
      pageLoaded: false,
      fields: [ //form fields validation
        { name: "fname", error: false, message: "" },
        { name: "lname", error: false, message: "" },
        { name: "email", error: false, message: "" },
        { name: "postcode", error: false, message: "" },
      ]
      ,
      emailPrefResp: { //required field validation
        IsSuccessful: false,
        Message: "",
        ApiResponse: null
      },
      submitted: false,
    };
  }

  componentDidMount() {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;

    this.setState({
      ...this.state,
      pageId: pageId,
      dbName: dbName,
      pageLoaded: true
    });
  }

  componentDidUpdate() {

    if (this.state.submitted) {
      const signupWrapper = document.body.querySelector(".signup")
      const invalidField = document.body.querySelector(".box-error")

      if (this.state.success) setTimeout(() => {
        if (signupWrapper) signupWrapper.scrollIntoView()
      }, 250)

      if (invalidField) {
        setTimeout(() => {
          invalidField.scrollIntoView()
        }, 250)
      }
    }

  }

  handlePreferencesResponse = (success, data, submitted) => {
    if (data.ApiResponse === 2 || data.ApiResponse === 5) { //duplicate email
      let fieldArrIndex = data.ApiResponse === 5 ? 3 : 2
      let fieldArr = this.state.fields
      fieldArr[fieldArrIndex].error = true
      fieldArr[fieldArrIndex].message = data.Message
      this.setState({
        ...this.state,
        success,
        submitted,
        emailPrefResp: { ...data },
        fields: fieldArr
      });
    } else {
      this.setState({ ...this.state, success, submitted, emailPrefResp: { ...data }, pageLoaded: success ? false : true })
    }
  }

  updateFieldsState = obj => {
    this.setState({ ...this.state, ...obj })
  }

  render() {
    const {
      SetSignUpTitle = null,
      SelectCategoriesDescription = null,
      SelectGenderDescription = null,
      PrivacyPolicyText = null,
      SubmitButtonLabel = null,
      Interests = null,
      GenderOptions = null,
      SuccessMessage = null,
      EnableRecaptcha = null,
      PrivateKey = null,
      PublicKey = null,
      FirstNameLabel = null,
      LastNameLabel = null,
      EmailLabel = null,
      PostcodeLabel = null
    } = this.props.fields || {};
    const {
      pageId,
      dbName,
      success,
      emailPrefResp,
      fields,
      pageLoaded,
      email,
      fname,
      lname,
      postcode
    } = this.state || {};

    const {
      IsSuccessful,
      Message,
    } = emailPrefResp

    const labels = {
      FirstNameLabel,
      LastNameLabel,
      EmailLabel,
      PostcodeLabel
    }

    const fieldsStates = { fname, lname, email, postcode }
    let emailPrefMessage = { value: Message }

    return (
      <BaseComponent margin="standard">
        <div className={`signup ${success ? "sucess-update" : ""}`}>
          {
            pageLoaded &&
            <div>
              <div className="signup__email-header"><Text field={SetSignUpTitle} /></div>
              {fields.length &&
                <SubscribeForm
                  fields={fields}
                  fieldsStates={fieldsStates}
                  setFields={this.updateFieldsState}
                  fieldsLabels={labels}
                />
              }

              <EmailPreference
                PageID={pageId}
                DBName={dbName}
                EmailAddress={email}
                FirstName={fname}
                LastName={lname}
                Postcode={postcode}
                Description={SelectCategoriesDescription}
                ButtonName={(SubmitButtonLabel && SubmitButtonLabel.value) ? SubmitButtonLabel.value : "Submit"}
                Interests={Interests}
                GenderDescription={SelectGenderDescription}
                GenderOptions={GenderOptions}
                RadioGroupName={"signup-Gender"}
                HideDropdown={false}
                EmailPreferenceResponse={(success, data, submitted) => {
                  this.handlePreferencesResponse(success, data, submitted)
                }
                }
                Container={"signup"}
                Endpoint={"signup"}
                EnableRecaptcha={EnableRecaptcha}
                RecaptchaPublicKey={PublicKey}
                RecaptchaPrivateKey={PrivateKey}
                PrivacyPolicyText={PrivacyPolicyText}
                ValidateFields={fields}
              />
            </div>
          }
          {
            success &&
            <div className="signup__message">
              <Text field={IsSuccessful ? SuccessMessage : emailPrefMessage} />
            </div>
          }
        </div>
      </BaseComponent>
    );
  }
}

export default SignUp;
