/*
** Source Code: https://www.codehousegroup.com/insight-and-inspiration/tech-stream/sitecore-forms-and-jss-conditional-logic-in-react
** Author: Aleksandar Kisimov
*/
import { Action_Constants, MatchType_Constants, Operator_Constants } from "../Constants";

const handleActionType = (actionType, value) => {
    const actions = {
        [Action_Constants["show"]]: () => {
            return !value;
        },
        [Action_Constants["hide"]]: () => {
            return value;
        },
        [Action_Constants["enable"]]: () => {
            return !value;
        },
        [Action_Constants["disable"]]: () => {
            return value;
        }
    }

    return actions[actionType]();
}

const validate = (operator, value, valueAgainst, isListBoxField) => {
    const operators = {
        [Operator_Constants["contains"]]: () => {
            const regex = RegExp(`${value}`, "g");
            return regex.test(valueAgainst);
        },
        [Operator_Constants["does_not_contain"]]: () => {
            const regex = RegExp(`${value}`, "g");
            return !(regex.test(valueAgainst));
        },
        [Operator_Constants["starts_with"]]: () => {
            // const regex = RegExp(`^${valueAgainst}`);
            // return regex.test(value);
            return value.startsWith(valueAgainst);
        },
        [Operator_Constants["does_not_start_with"]]: () => {
            // const regex = RegExp(`^${valueAgainst}`);
            // return !(regex.test(value));
            return !value.startsWith(valueAgainst);
        },
        [Operator_Constants["ends_with"]]: () => {
            // const regex = RegExp(`${valueAgainst}$`);
            // return regex.test(value);
            return value.endsWith(valueAgainst);
        },
        [Operator_Constants["does_not_end_with"]]: () => {
            // const regex = RegExp(`${valueAgainst}$`);
            // return !(regex.test(value));
            return !value.endsWith(valueAgainst);
        },
        [Operator_Constants["is_equal_To"]]: () => {
            let result;
            if (isListBoxField) {
                const regex = RegExp(`${value}`, "g");
                result = regex.test(valueAgainst);
            } else {
                result = (value === valueAgainst);
            }
            return result;
        },
        [Operator_Constants["is_not_equal_to"]]: () => {
            let result;
            if (isListBoxField) {
                const regex = RegExp(`${value}`, "g");
                result = !(regex.test(valueAgainst));
            } else {
                result = (value !== valueAgainst);
            }
            return result;
        },
        [Operator_Constants["is_greater_than"]]: () => {
            return value > valueAgainst;
        },
        [Operator_Constants["is_greater_than_or_equal_to"]]: () => {
            return value >= valueAgainst;
        },
        [Operator_Constants["is_less_than"]]: () => {
            return value < valueAgainst;
        },
        [Operator_Constants["is_less_than_or_equal_to"]]: () => {
            return value <= valueAgainst;
        }
    };

    if (typeof operators[operator] === 'undefined') {
        return false;
    }

    return operators[operator]();
};

const getValidation = (elemValue, item, isListBoxField) => {
    let shouldHide;

    for (let i = 0; i < item.conditions.length; i += 1) {
        const value = item.conditions[i].value.toString().toLowerCase(),
            operator = item.conditions[i].operatorId,
            valueAgainst = elemValue.toString().toLowerCase();

        shouldHide = validate(operator, value, valueAgainst, isListBoxField);

        if (shouldHide) {
            break;
        }
    }
    let result = shouldHide;
    return handleActionType(item.actions[0].actionTypeId, result);
};

// Manual Trigger Event
const triggerEvent = (element, event) => {
    var evt;
    if (document.createEvent) {
        evt = document.createEvent("HTMLEvents");
        evt.initEvent(event, true, true);
        evt.eventName = event;
        element.dispatchEvent(evt);
    } else {
        evt = document.createEventObject();
        evt.eventName = event;
        evt.eventType = event;
        element.fireEvent("on" + evt.eventType, evt);
    }
}
export const hideElement = (elemValue, fieldConditions, isListBoxField) => {
    setTimeout(() => {
        let shouldHide;
        for (let i = 0; i < fieldConditions.length; i += 1) {
            shouldHide = getValidation(elemValue, fieldConditions[i], isListBoxField);
            let actionsList = fieldConditions[i].actions;
            for (let j = 0; j < actionsList.length; j += 1) {
                let fieldId = actionsList[j].fieldId;
                let elemfieldId = document.querySelector('[data-fieldkey = "' + fieldId + '"]');
                let sectionField = "";
                let fieldList = "";
                if (elemfieldId) {
                    if (shouldHide) {
                        elemfieldId.classList.add("is-hidden");
                        elemfieldId.classList.remove("is-invalid");
                        elemfieldId.classList.remove("is-to-validate");
                        sectionField = elemfieldId.querySelector("fieldset");
                        if (sectionField) {
                            fieldList = sectionField.querySelectorAll(".form-group");
                            if (fieldList && fieldList.length > 0) {
                                Array.prototype.slice.call(fieldList).map((elem, index) => {
                                    elem.classList.add("is-hidden");
                                    elem.classList.remove("is-invalid");
                                    elem.classList.remove("is-to-validate");
                                    if (/form-single-line/g.test(elem.className)) {
                                        let inputElem = elem.querySelector("input");
                                        if (inputElem) {
                                            inputElem.value = null;
                                            triggerEvent(inputElem, "change");
                                        }
                                    }
                                    if (/form-date/g.test(elem.className)) {
                                        let inputElem = elem.querySelector(".react-datepicker-wrapper input");
                                        if (inputElem) {
                                            inputElem.value = null;
                                            triggerEvent(inputElem, "change");
                                        }
                                    }
                                    if (/form-textarea/g.test(elem.className)) {
                                        let textArea = elem.querySelector("textarea");
                                        if (textArea) {
                                            textArea.value = null;
                                            triggerEvent(textArea, "change");
                                        }
                                    }
                                    if (/form-checkbox-only/g.test(elem.className)) {
                                        let inputElem = elem.querySelector("input");
                                        if (inputElem) {
                                            if (inputElem.checked) {
                                                inputElem.click();
                                            }
                                        }
                                    }
                                    if (/form-checkboxlist/g.test(elem.className)) {
                                        let inputElem = elem.querySelectorAll("input");
                                        Array.prototype.slice.call(inputElem).map((elem, index) => {
                                            if (elem) {
                                                if (elem.checked) {
                                                    elem.click();
                                                }
                                            }
                                        });
                                    }
                                    if (/form-radiobutton/g.test(elem.className)) {
                                        let inputElem = elem.querySelectorAll("input");
                                        Array.prototype.slice.call(inputElem).map((elem, index) => {
                                            if (elem.value === "null") {
                                                elem.click();
                                            }
                                        });
                                    }
                                    if (/form-dropdown/g.test(elem.className)) {
                                        const hiddenOption = elem.querySelector(".hidden-option");
                                        if (hiddenOption) {
                                            hiddenOption.click();
                                        }
                                    }
                                    if (/form-listbox/g.test(elem.className)) {
                                        const selectElem = elem.querySelector("select");
                                        selectElem.value = null;
                                        triggerEvent(selectElem, "change");
                                    }
                                });
                            }
                        } else {
                            if (/form-single-line/g.test(elemfieldId.className)) {
                                let inputElem = elemfieldId.querySelector("input");
                                if (inputElem) {
                                    inputElem.value = null;
                                    triggerEvent(inputElem, "change");
                                }
                            }
                            if (/form-date/g.test(elemfieldId.className)) {
                                let inputElem = elemfieldId.querySelector(".react-datepicker-wrapper input");
                                if (inputElem) {
                                    inputElem.value = null;
                                    triggerEvent(inputElem, "change");
                                }
                            }
                            if (/form-textarea/g.test(elemfieldId.className)) {
                                let textArea = elemfieldId.querySelector("textarea");
                                if (textArea) {
                                    textArea.value = null;
                                    triggerEvent(textArea, "change");
                                }
                            }
                            if (/form-checkbox-only/g.test(elemfieldId.className)) {
                                let inputElem = elemfieldId.querySelector("input");
                                if (inputElem) {
                                    if (inputElem.checked) {
                                        inputElem.click();
                                    }
                                }
                            }
                            if (/form-checkboxlist/g.test(elemfieldId.className)) {
                                let inputElem = elemfieldId.querySelectorAll("input");
                                Array.prototype.slice.call(inputElem).map((elem, index) => {
                                    if (elem) {
                                        if (elem.checked) {
                                            elem.click();
                                        }
                                    }
                                });
                            }
                            if (/form-radiobutton/g.test(elemfieldId.className)) {
                                let inputElem = elemfieldId.querySelectorAll("input");
                                Array.prototype.slice.call(inputElem).map((elem, index) => {
                                    if (elem.value === "null") {
                                        elem.click();
                                    }
                                });
                            }
                            if (/form-dropdown/g.test(elemfieldId.className)) {
                                const hiddenOption = elemfieldId.querySelector(".hidden-option");
                                if (hiddenOption) {
                                    hiddenOption.click();
                                }
                            }
                            if (/form-listbox/g.test(elemfieldId.className)) {
                                const selectElem = elemfieldId.querySelector("select");
                                selectElem.value = null;
                                triggerEvent(selectElem, "change");
                            }
                        }
                    } else {
                        elemfieldId.classList.remove("is-hidden");
                        elemfieldId.classList.add("is-to-validate");

                        sectionField = elemfieldId.querySelector("fieldset");
                        if (sectionField) {
                            fieldList = sectionField.querySelectorAll(".form-group");
                            if (fieldList && fieldList.length > 0) {
                                Array.prototype.slice.call(fieldList).map((elem, index) => {
                                    elem.classList.remove("is-hidden");
                                    elem.classList.add("is-to-validate");

                                    if (/form-checkbox-only/g.test(elem.className)) {
                                        let hyperlinkLabel = elem.querySelector(".hyperlink-container label");
                                        let labelElem = elem.querySelector("label");
                                        let labelHeight = hyperlinkLabel.clientHeight;
                                        labelElem.style.height = labelHeight + "px";
                                    }
        
                                    if (/form-checkboxlist/g.test(elem.className)) {
                                        let hyperlinkContainer = elem.querySelector(".hyperlink-container");
                                        let checkboxLabel;
                                        if (hyperlinkContainer) {
                                            checkboxLabel = hyperlinkContainer.querySelectorAll("label");
                                        }
                                        
                                        if (checkboxLabel) {
                                            Array.prototype.slice.call(checkboxLabel).map((checkboxLabel, index) => {
                                                let checkboxLabelHeight = checkboxLabel.clientHeight;
                                                elem.querySelectorAll("label")[index + 1].style.maxHeight = checkboxLabelHeight + "px";
                                            })
                                        }
                                    }
                                });
                            }
                        } else {
                            if (/form-checkbox-only/g.test(elemfieldId.className)) {
                                let hyperlinkLabel = elemfieldId.querySelector(".hyperlink-container label");
                                let labelElem = elemfieldId.querySelector("label");
                                let labelHeight = hyperlinkLabel.clientHeight;
                                labelElem.style.height = labelHeight + "px";
                            }

                            if (/form-checkboxlist/g.test(elemfieldId.className)) {
                                let hyperlinkContainer = elemfieldId.querySelector(".hyperlink-container");
                                let checkboxLabel;
                                if (hyperlinkContainer) {
                                    checkboxLabel = hyperlinkContainer.querySelectorAll("label");
                                }
                                
                                if (checkboxLabel) {
                                    Array.prototype.slice.call(checkboxLabel).map((checkboxLabel, index) => {
                                        let checkboxLabelHeight = checkboxLabel.clientHeight;
                                        elemfieldId.querySelectorAll("label")[index + 1].style.maxHeight = checkboxLabelHeight + "px";
                                    })
                                }
                            }
                        }
                    }
                }
            }
        }
        return shouldHide;
    }, 250);
};