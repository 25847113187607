import React, { Component, Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import ReCAPTCHA from "react-google-recaptcha";
import DOMPurify from 'dompurify';
import TagManager from "react-gtm-module";
import axios from "axios";
import Form from "../ContactUsInputFields";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureFormsContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      userEmailAddress: "",
      complete: false,
      submitted: false,
      successMessage: null,
      feedback: null,
      show: false,
      verifiedRecaptcha: false,
      expiredRecaptcha: false,
      load: false,
      recaptchaLoaded: false
    };
    this.topic = React.createRef();
    this.inputField = React.createRef();
  }

  componentDidMount() {
    this.setState({ feedback: this.topic.current.value });
    setTimeout(() => {
      this.setState({ load: true });
    }, 1500);
  }

  recaptchaLoaded = () => {
    console.log("Successful Load Recaptcha");
    this.setState({ recaptchaLoaded: true });
  };

  recaptchaExpired = () => {
    this.setState({
      verifiedRecaptcha: false,
      expiredRecaptcha: true
    });
  };

  recaptchaVerifyResponse = response => {
    if (response) {
      this.setState({
        verifiedRecaptcha: true,
        expiredRecaptcha: false
      });
    } else {
      this.setState({ verifiedRecaptcha: false });
    }
  };

  handleToggle = e => {
    e.target.focus();
    this.setState({ show: !this.state.show });
  };

  handleBlur = e => {
    if (this.state.show) {
      setTimeout(() => {
        this.setState({ show: !this.state.show });
      }, 250);
    }
  };

  handleSubmitBtn = e => {
    e.preventDefault();
    let contactUsWrapper = document.querySelector(".contactus-wrapper");
    const contactUsTopic = document.getElementById("Topic");
    let formsWrapper = e.target,
      submitBtn = formsWrapper.querySelector(".contactus-submit-btn");
    const { verifiedRecaptcha } = this.state;
    const recaptchaWrapper = document
      .getElementsByClassName("contactus-form")[0]
      .querySelector(".form-recaptcha");
    this.setState({ submitted: true });

    setTimeout(() => {
      let invalidField = formsWrapper.querySelector(".form-control-error");
      if (invalidField) {
        invalidField.scrollIntoView();
      }
    }, 250);

    if (recaptchaWrapper) {
      if (!verifiedRecaptcha) {
        recaptchaWrapper.classList.add("is-required");
        return;
      }
    }
    if (!this.state.complete) return;

    submitBtn.setAttribute("disabled", "");

    const { SuccessMessage = null, FailedMessage = null } =
      this.props.fields || {};
    const dataSource = this.props.rendering.dataSource || "";
    const url = `/api/sitecore/forms/contactus`;
    let fields = [];

    // key compose field data
    fields = this.state.fields.map(field => {
      return {
        FieldName: field.fieldName,
        Value: field.value
      };
    });

    // add topic
    fields.push({
      FieldName: "Topic",
      Value: this.topic.current.value
    });

    let postData = {
      ContactUsDetails: {
        ItemID: dataSource,
        UserEmailAddress: this.state.userEmailAddress,
        Fields: fields,
        SuccessMessage: SuccessMessage.value,
        FailedMessage: FailedMessage.value
      }
    };

    axios({
      url,
      method: "POST",
      data: postData
    })
      .then(response => {
        if (response && response.data && response.data.message) {
          this.setState({ successMessage: response.data.message });
          TagManager.dataLayer({
            dataLayer: {
              event: "contactUsFormSubmit",
              contactUsTopic: contactUsTopic && contactUsTopic.value
            },
            dataLayerName: "dataLayer"
          });
          contactUsWrapper.scrollIntoView();
        }
      })
      .catch(error => {
        submitBtn.removeAttribute("disabled");
        console.error(error);
      });
  };

  updateFieldValue = (fieldName, value, Type, isRequired, validInput) => {
    let userEmailAddress = null;
    let fields = []; //.concat(this.state.fields);
    let fieldIsNew = true;

    if (Type.toLowerCase() === "email address field") {
      userEmailAddress = value;
    }

    // look for existing fields
    fields = this.state.fields.map(field => {
      if (field.fieldName === fieldName) {
        field.value = value;
        fieldIsNew = false;
      }
      return field;
    });

    if (fieldIsNew) {
      fields.push({
        fieldName: fieldName,
        value: value,
        required: isRequired
      });
    }

    let complete = null;
    
    for (let i = 0; i < this.props.fields.Fields.length; i++) {
      if (this.topic.current.form[i]) {
        if (
          !this.topic.current.form[i].value &&
          this.topic.current.form[i].required
        ) {
          complete = false;
          break;
        } else {
          complete = true;
        }
      }
    }

    this.setState({
      ...this.state,
      fields: fields,
      userEmailAddress: userEmailAddress
        ? userEmailAddress
        : this.state.userEmailAddress,
      complete: !validInput ? false : complete
    });
  };

  onChangeSelection = name => {
    this.setState({ feedback: name });
  };

  render() {
    const { verifiedRecaptcha, expiredRecaptcha, load } = this.state;
    const {
      PageSummary = null,
      Fields = null,
      EnableRecaptcha = null,
      PublicKey = null
    } = this.props.fields || {};

    let message = null;

    if (this.state.successMessage) {
      message = (
        <div
          className="thanks-for-contacting"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.successMessage) }}
        />
      );
    }

    return (
      <BaseComponent margin="standard">
        <div className="contactus-wrapper">
          {message ? (
            message
          ) : (
              <Fragment>
                <div className="contactus-description">
                  <Text field={PageSummary} />
                </div>
                <form onSubmit={this.handleSubmitBtn} noValidate>
                  <div className="contactus-form">
                    {Fields &&
                      Fields.map((listFields, index) => {
                        let { Name, Type } = listFields.fields || {};
                        if (
                          Type &&
                          Type.value.toLowerCase() === "heading field"
                        ) {
                          return (
                            <div
                              className="contactus-subheading"
                              key={Name.value + index}
                            >
                              {Name.value}
                            </div>
                          );
                        }
                        if (
                          Type &&
                          (Type.value.toLowerCase() === "text field" ||
                            Type.value.toLowerCase() === "email address field" ||
                            Type.value.toLowerCase() === "phone number field")
                        ) {
                          return (
                            <Form
                              info={listFields.fields}
                              key={Type.value + index}
                              updateFieldValue={this.updateFieldValue}
                              submitted={this.state.submitted}
                            />
                          );
                        }
                        if (Type && Type.value.toLowerCase() === "droplist") {
                          let { Values } = listFields.fields || {};
                          const defaultValue = Values[0].fields.Name.value;
                          return (
                            <div className="row" key={Type.value + index}>
                              <div className="col col-md-12">
                                <div className="form-group contactus-dropdown">
                                  <div className="dropdown-container">
                                    <div
                                      className={`arrow ${
                                        this.state.show ? "active" : ""
                                        }`}
                                      onClick={this.handleToggle}
                                      onBlur={this.handleBlur}
                                      tabIndex="0"
                                    >
                                      <div className="dropdown-placeholder">
                                        <div className="label">
                                          <Text field={Name} />
                                        </div>
                                        <input
                                          id={
                                            Name && Name.value
                                              ? Name.value
                                              : `dropdown${index}`
                                          }
                                          type="button"
                                          value={
                                            this.state.feedback
                                              ? this.state.feedback
                                              : defaultValue
                                          }
                                          className="dropdown-btn"
                                          ref={this.topic}
                                        />
                                      </div>
                                      <i className="icon icon-arrow-down" />
                                    </div>
                                    <ul
                                      className="dropdown-list"
                                      hidden={!this.state.show}
                                    >
                                      {Values &&
                                        Values.map((listTopic, index) => {
                                          let { Name } = listTopic.fields || {};
                                          if (
                                            this.state.feedback === Name.value
                                          ) {
                                            return (
                                              <li
                                                className="active"
                                                key={`optionID-${index}`}
                                              >
                                                <i className="icon icon-check" />
                                                <span>{Name.value}</span>
                                              </li>
                                            );
                                          } else {
                                            return (
                                              <li
                                                key={`optionID-${index}`}
                                                onClick={() =>
                                                  this.onChangeSelection(
                                                    Name.value
                                                  )
                                                }
                                              >
                                                <i className="icon icon-check hide" />
                                                <span>{Name.value}</span>
                                              </li>
                                            );
                                          }
                                        })}
                                    </ul>
                                  </div>
                                </div>
                                {Values &&
                                  Values.map((listTopic, index) => {
                                    const {
                                      LinkedFields: LinkedFields = [],
                                      Name
                                    } = listTopic.fields || {};
                                    if (LinkedFields.length > 0) {
                                      return (
                                        <div
                                          className="js-form-dropdown"
                                          key={
                                            Name && Name.value
                                              ? Name.value + index
                                              : "dropdown " + index
                                          }
                                        >
                                          {LinkedFields.map(
                                            (linkFields, index) => {
                                              if (
                                                linkFields.fields.Type &&
                                                (linkFields.fields.Type.value.toLowerCase() ===
                                                  "text field" ||
                                                  linkFields.fields.Type.value.toLowerCase() ===
                                                  "email address field" ||
                                                  linkFields.fields.Type.value.toLowerCase() ===
                                                  "phone number field")
                                              ) {
                                                return (
                                                  (Name.value.toLowerCase() ===
                                                    defaultValue.toLowerCase() ||
                                                    (this.state.feedback &&
                                                      Name.value.toLowerCase() ===
                                                      this.state.feedback.toLowerCase())) && (
                                                    <Form
                                                      info={linkFields.fields}
                                                      key={
                                                        "form-dropdown " +
                                                        linkFields.fields.Type
                                                          .value +
                                                        index
                                                      }
                                                      updateFieldValue={
                                                        this.updateFieldValue
                                                      }
                                                      submitted={
                                                        this.state.submitted
                                                      }
                                                      isDropDownField={true}
                                                    />
                                                  )
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          );
                        }
                        if (
                          Type &&
                          Type.value.toLowerCase() === "multi-line field"
                        ) {
                          return (
                              <Form
                              info={listFields.fields}
                              key={Type.value + index}
                              updateFieldValue={this.updateFieldValue}
                              submitted={this.state.submitted}
                            />
                          );
                        }
                      })}
                    {load &&
                      EnableRecaptcha &&
                      EnableRecaptcha.value &&
                      PublicKey &&
                      PublicKey.value && (
                        <div
                          className={
                            "form-group form-recaptcha " +
                            (verifiedRecaptcha ? "verifiedRecaptcha " : " ") +
                            (expiredRecaptcha ? "is-required" : " ")
                          }
                        >
                          <ReCAPTCHA
                            size="normal"
                            sitekey={
                              PublicKey && PublicKey.value
                                ? PublicKey.value
                                : "6LeqAfYUAAAAAGK1oZ1NzfVUFeC4kqJOe16XklGg"
                            }
                            onChange={this.recaptchaVerifyResponse}
                            onExpired={this.recaptchaExpired}
                            asyncScriptOnLoad={this.recaptchaLoaded}
                          />
                          <div className="form-control-validation">
                            <span>
                              <label>ReCaptcha is required</label>
                            </span>
                          </div>
                        </div>
                      )}
                    <div className="contactus-button">
                      <button className="contactus-submit-btn" type="submit">
                        Send
                    </button>
                    </div>
                  </div>
                </form>
              </Fragment>
            )}
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureFormsContactUs;
